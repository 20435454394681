import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: () => import(/* webpackChunkName: "about" */ '../views/homeIndex/homeIndex.vue'),
        name: 'homeIndex',
      },
      {
        path: '/construction',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'construction',
      },
      {
        path: '/application',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'application',
      },
      {
        path: '/marketing',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'marketing',
      },
      {
        path: '/business',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'business',
      },
      {
        path: '/cultivate',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'cultivate',
      },
      {
        path: '/onlineRetailers',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'onlineRetailers',
      },
      {
        path: '/policyConsultation',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'policyConsultation',
      },
      {
        path: '/administration',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'administration',
      },
      {
        path: '/finance',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'finance',
      },
      {
        path: '/law',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'law',
      },
      {
        path: '/propertyRight',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'propertyRight',
      },
      {
        path: '/brand',
        component: () => import(/* webpackChunkName: "about" */ '../views/digitalConstruction/Construction.vue'),
        name: 'brand',
      },
      {
        path: '/dynamic',
        component: () => import(/* webpackChunkName: "about" */ '../views/dynamic/Dynamic.vue'),
        name: 'dynamic',
      },

      {
        path: '/offlineServiceOutlets',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/offlineServiceOutlets/offlineServiceOutlets.vue'),
        name: 'offlineServiceOutlets',
      },
    ],
  },
  {
    path: '/more',
    redirect: '/more/list',
    component: () => import(/* webpackChunkName: "about" */ '../views/allDynamic/allDynamic.vue'),
    children: [
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "about" */ '../views/allDynamic/ConsultationList.vue'),
        name: 'consultationList',
        meta: {
          isDynamic: true, //是否为平台动态页面
          title: '平台动态',
        },
      },
      {
        path: 'offlineServiceOutlets/list',
        component: () => import(/* webpackChunkName: "about" */ '../views/offlineServiceOutlets/components/list.vue'),
        name: 'offlineServiceOutletsList',
        meta: {
          isDynamic: true, //是否为平台动态页面
          title: '线下服务网点',
        },
      },
      {
        path: 'offlineServiceOutlets/listInfo',
        component: () => import(/* webpackChunkName: "about" */ '../views/offlineServiceOutlets/components/detail.vue'),
        name: 'offlineServiceOutletsListInfo',
        meta: {
          isDynamic: true, //是否为平台动态页面
          title: '线下服务网点',
        },
      },
      {
        path: 'branch',
        component: () => import(/* webpackChunkName: "about" */ '../views/branch/branch.vue'),
        name: 'branch',
      },
      {
        path: 'list/info',
        component: () => import(/* webpackChunkName: "about" */ '../views/allDynamic/ListInfo.vue'),
        name: 'listInfo',
        meta: {
          isDynamic: true, //是否为平台动态页面
          title: '平台动态',
          // isSignIn:true
        },
      },
      {
        path: 'serviceProviderCertification',
        name: 'serviceProviderCertification',
        component: () => import('@/views/serviceProviderCertification/serviceProviderCertification'),
      },
      {
        path: 'aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aboutUs/aboutUs'),
      },
      {
        path: 'platformRules',
        name: 'platformRules',
        component: () => import('@/views/platformRules/platformRules'),
      },
      {
        path: 'legalNotices',
        name: 'legalNotices',
        component: () => import('@/views/legalNotices/legalNotices'),
      },
      {
        path: 'liveBroadcast',
        name: 'liveBroadcast',
        component: () => import('@/views/liveBroadcast/index.vue'),
      },
      {
        path: 'videoId',
        name: 'videoId',
        component: () => import('@/views/videoId/index.vue'),
      },
      {
        path: 'product',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/productList.vue'),
        name: 'productList',
        meta: {
          isDynamic: false, //是否为平台动态页面
        },
      },
      {
        path: 'product/info',
        component: () => import(/* webpackChunkName: "about" */ '../views/product/productInfo.vue'),
        name: 'productInfo',
        meta: {
          isDynamic: false, //是否为平台动态页面
        },
      },
      {
        path: 'financingNeeds',
        component: () => import(/* webpackChunkName: "about" */ '../views/financingNeeds/financingNeeds.vue'),
        name: 'financingNeeds',
        meta: {
          isDynamic: false, //是否为平台动态页面
        },
      },
      {
        path: 'serviceProvider',
        component: () => import(/* webpackChunkName: "about" */ '../views/serviceProvider/serviceProvider.vue'),
        name: 'serviceProvider',
        meta: {
          isDynamic: true, //是否为平台动态页面
          title: '服务商供商',
        },
      },
      {
        path: 'serviceProvider/detail',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/serviceProvider/components/detail/detail.vue'),
        name: 'serviceProviderDetail',
        meta: {
          // isDynamic: true, //是否为平台动态页面
          title: '服务商供商',
        },
      },
      {
        path: 'personalCenter',
        component: () => import(/* webpackChunkName: "about" */ '../views/personalCenter/personalCenter.vue'),
        name: 'personalCenter',
        meta: {
          isDynamic: false, //是否为平台动态页面
        },
      },

      {
        path: 'chat',
        component: () => import(/* webpackChunkName: "about" */ '../views/chat/chat.vue'),
        name: 'chat',
        meta: {
          isDynamic: false, //是否为平台动态页面
          isSignIn: true,
        },
      },
      {
        path: 'orderChat',
        component: () => import(/* webpackChunkName: "about" */ '../views/orderChat/chat.vue'),
        name: 'orderChat',
        meta: {
          isDynamic: false, //是否为平台动态页面
          isSignIn: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/accountModule/Login.vue'),
  },
  {
    path: '*',
    redirect: '/home',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch((err) => {})
}

export default router
