import { axios } from '@/utils/request'

//文件上传
export function uploadFile(data) {
  return axios({
    url: `/api/file/file/anyone/upload`,
    method: 'post',
    data,
  })
}

//字典查询
export function dictionary(data) {
  return axios({
    url: `/api/authority/dictionary/queryByTypes`,
    method: 'post',
    data,
  })
}

//服务一级分类
// export function serviceFirstClassify(data) {
//   return axios({
//     url: `/api/kjcy/bServiceCategory/query`,
//     method: 'post',
//     data,
//   })
// }
export function serviceFirstClassify(data) {
  return axios({
    url: `/api/kjcy/bServiceCategory/serviceCategoryListByPage`,
    method: 'post',
    data,
  })
}

//服务二级分类
export function serviceSecondClassify(data) {
  return axios({
    url: `/api/kjcy/bServiceCategory/list/category`,
    method: 'post',
    data,
  })
}

//产品、服务商、消息、案例-精选查询
export function serviceList(data) {
  return axios({
    url: `/api/kjcy/bServiceCategoryRelevanceSet/home/list`,
    method: 'post',
    data,
  })
}

//产品、服务商、消息、案例-分类查询
export function serviceClassifyList(data) {
  return axios({
    // url: `/api/kjcy/bService/page`,
    url: `/api/kjcy/bService/list`,
    method: 'post',
    data,
  })
}

//获取专属方案
export function exclusivePlan(data) {
  return axios({
    url: `/api/kjcy/bClue/customer/start`,
    method: 'post',
    data,
  })
}

//获取统计数据
export function statisticsInfo(data) {
  return axios({
    url: `/api/kjcy/bStatistics/home/data/Statistics`,
    method: 'post',
    data,
  })
}
//获取客服信息
export function customerInfo(data) {
  return axios({
    url: `/api/authority/parameter/query`,
    method: 'post',
    data,
  })
}

export function bArticleInfo(id) {
  return axios({
    url: `/api/kjcy/bArticle/getInfo/${id}`,
    method: 'get',
  })
}
export function bPlatformSetListByPage(data) {
  return axios({
    url: '/api/kjcy/bPlatformSet/bPlatformSetListByPage',
    method: 'post',
    data,
  })
}
