import Vue from 'vue'
import md5 from 'js-md5'
import {
    visitorLogin,
    accountPasswordLogin,
    getInfo,
    logout,
    visitorInfo
} from '@/api/login'
import {
    setVisitorToken,
    setUserToken,
    removeUserToken,
    getVisitorTokenKey
} from '@/utils/auth'
import {serviceFirstClassify} from '@/api/common'
import {userInfo} from "@/store/getters";

// 账号密码登录
export function signIn({commit}, userInfo) {
    return new Promise((resolve, reject) => {
        if(userInfo.password){
            userInfo.password = md5(userInfo.password)
        }
        accountPasswordLogin(userInfo)
            .then(res => {
                let data = res.data
                setUserToken(data.token, data.expire / 60 / 60 / 24)
                Vue.ls.set('userId', data.userId)
                commit('SET_USER_TYPE', 'user')
                resolve(res.data)
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

//获取游客token
export function getVisitorToken({commit}, data) {
    return new Promise((resolve, reject) => {
        visitorLogin(data)
            .then(res => {
                const visitor = res.data
                const avatar = visitor.avatar
                    ? visitor.avatar
                    : require('@/assets/images/avatar.jpg')

                commit('SET_NAME', visitor.name)
                commit('SET_USER_TYPE', 'visitor')
                commit('SET_AVATAR', avatar)
                commit('SET_USERID', visitor.id)
                setVisitorToken(res.data.token, res.data.expire / 60 / 60 / 24)
                resolve()
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 获取游客信息
export function getVisitorInfo({commit}) {
    return new Promise((resolve, reject) => {
        visitorInfo({token: 'Bearer ' + getVisitorTokenKey()})
            .then(res => {
                const user = res.data
                const avatar = user.avatar
                    ? user.avatar
                    : require('@/assets/images/avatar.jpg')
                commit('SET_NAME', user.name)
                commit('SET_AVATAR', avatar)
                commit('SET_USER_TYPE', 'visitor')
                commit('SET_USERID', user.userId)
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
    })
}

// 获取用户信息
export function getUserInfo({commit}) {
    return new Promise((resolve, reject) => {
        getInfo({id: Vue.ls.get('userId')})
            .then(res => {
                const user = res.data
                const avatar = user.avatar
                    ? user.avatar
                    : require('@/assets/images/avatar.jpg')
                // localStorage.setItem('userInfo',JSON.stringify(user))
                commit('SET_NAME', user.name)
                commit('SET_AVATAR', avatar)
                commit('SET_USERID', user.id)
                commit('SET_USER_TYPE', 'user')
                commit('SET_USERINFO', user)
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
    })
}

// 获取一级分类
export function queryPrimaryClassify({commit}) {
    return new Promise((resolve, reject) => {
        serviceFirstClassify({
            current: 1,
            size: 999,
            model: {
                delFlag: "",
                endTime: "",
                keyword: "",
                parentId: "0",
                startTime: "",
                time: "",
                value: [],
            }
        })
            .then(res => {
                let slider = []
                let top = []
                res.data.records.forEach(item => {
                    if (
                        item.path === '/scienceAndTechnologyPolicy' ||
                        item.path === '/parkInvestment' ||
                        item.path === '/parkService'
                    ) {
                        slider.push(item)
                    } else {
                        top.push(item)
                    }
                })
                commit('SET_NAV_CLASSIFY', [...slider, ...top])
                resolve()
            })
            .catch(error => {
                reject(error)
            })
    })
}

// 退出系统
export function LogOut({commit, state}) {
    return new Promise((resolve, reject) => {
        logout(state.userId)
            .then(() => {
                // localStorage.clear()
                commit('SET_TOKEN', '')
                commit('SET_NAME', '')
                commit('SET_USER_TYPE', '')
                commit('SET_AVATAR', '')
                commit('SET_USERID', '')
                commit('SET_USERINFO', {})
                removeUserToken()
                resolve()
            })
            .catch(error => {
                reject(error)
            })
    })
}
