<template>
  <div class="main_content">
    <!-- 基础样式模块 -->
    <div v-if="modeNum == 1" class="mdoe1">
      <!-- 顶部 -->
      <div class="con_top">
        <div class="logo" @click="$router.push('/')"></div>
        <div class="right">
          <div class="search">
            <el-input class="searchIpt" placeholder="搜索" v-model="keyword"></el-input>
            <el-button class="ser_btn" @click="toProduct"><span>搜索</span></el-button>
          </div>
          <el-button class="login" v-if="!userInfo || JSON.stringify(userInfo) == '{}'" @click="toLogin"
            ><span>登录</span>
          </el-button>
          <el-button class="consult" @click="dialogVisible = true"><span>咨询</span></el-button>
          <div class="flex person" v-if="userInfo && JSON.stringify(userInfo) != '{}'">
            <div class="drop">
              <img class="cover" :src="userInfo.avatar || require('@/assets/images/avatar.jpg')" />
              <el-dropdown>
                <span class="name"> {{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i> </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="toPersonal({})">个人中心</el-dropdown-item>
                  <el-dropdown-item @click.native="signOut">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div>/</div>
            <div class="drop">
              <el-badge
                @click.native="toPersonal({ currentIndex: '2' })"
                :value="userType === 'user' ? statistics.pendingOrderNum : ''"
                class="item"
              >
                <i class="el-icon-s-claim"></i>待确认
              </el-badge>
            </div>
            <div>/</div>
            <div class="drop">
              <el-badge
                @click.native="toPersonal({ currentIndex: '2' })"
                :value="userType === 'user' ? statistics.pendingPaymentOrderNum : ''"
                class="item"
              >
                <i class="el-icon-s-order"></i>

                待付款
              </el-badge>
            </div>
            <div>/</div>
            <div class="drop">
              <el-badge
                @click.native="toPersonal({ currentIndex: '2' })"
                :value="userType === 'user' ? statistics.orderInServiceNum : ''"
                class="item"
              >
                <i class="el-icon-tickets"></i>服务中
              </el-badge>
            </div>
            <div>/</div>
            <div class="drop">
              <el-badge
                @click.native="toPersonal({ currentIndex: '0' })"
                :value="userType === 'user' ? statistics.unreadConsultNum : ''"
                class="item"
              >
                <i class="el-icon-chat-dot-round"></i>消息
              </el-badge>
            </div>
            <div>/</div>
            <div class="drop" @click="toPersonal({ currentIndex: '2' })"><i class="el-icon-document"></i>订单</div>
          </div>
        </div>
      </div>
      <!-- 主要显示区域 -->
      <div class="home_main" :class="{ isHome: $route.path == '/home' }">
        <div class="top_nav">
          <div
            class="btn_nav"
            v-for="(item, index) in mainTopNav"
            :key="index"
            @click="cliTopNav(index, mainTopNav, item)"
            :class="{ isActive: item.isActiveCl, boder_none: index == 6 }"
          >
            <span :style="item.imgurl">{{ item.categoryName }}</span>
          </div>
        </div>
        <div class="routerView">
          <router-view @initiateCooperation="initiateCooperation" />
        </div>

        <div class="top_nav bottom_nav">
          <div
            class="btn_nav"
            v-for="(item, index) in mainBottomNav"
            :key="index"
            @click="cliTopNav(index, mainBottomNav, item)"
            :class="{ isActive: item.isActiveCl, boder_none: index == 6 }"
          >
            <span :style="item.imgurl">{{ item.categoryName }}</span>
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <div class="con_bottom">
        <div class="left">
          <div class="ewm">
            <img :src="WxUrl" style="width: 100%; height: 100%; border-radius: 50%" alt="" />
          </div>
          <div class="text">
            <p>扫一扫</p>
            <p>进入小程序</p>
          </div>
        </div>
        <div class="left" style="margin-left: 250px">
          <div class="ewm wx" style="width: 100px; height: 100px">
            <img src="../assets/images/qiye.png" style="width: 100%; height: 100%" alt="" />
          </div>
          <div class="text">
            <p>扫一扫</p>
            <p>微信咨询</p>
          </div>
        </div>

        <div class="bottom_center">
          <div class="bot_top">
            <div
              class="hvr-float-shadow"
              v-for="(item, index) in bottomList"
              :key="index"
              :class="{ no_boder: bottomList.length == index + 1 }"
              @click="toGo(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="bot_bot">
            成都久广科技有限公司 版权所有&nbsp;
            <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备15002876号-3</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台动态模块 -->
    <!-- <div v-if="modeNum == 2" class="mode2">
      111
      <router-view></router-view>
    </div> -->
    <el-dialog title="客服热线" :visible.sync="dialogVisible" width="20%" class="kfDialog">
      <div>
        <div>
          <p>客服二维码</p>
          <div>
            <el-avatar shape="square" :size="100" :src="chatInfo.qrcode" fill="fill"></el-avatar>
          </div>
        </div>
        <el-divider />
        <div>
          <p class="el-icon-chat-dot-round wxChat" @click="toWechat" style="margin-bottom: 0">
            <span>在线客服</span>
          </p>
        </div>
        <el-divider />
        <div>
          <p>客服电话</p>
          <div class="el-icon-phone">
            {{ chatInfo.phone }}
          </div>
        </div>
        <el-divider />
        <div>
          <p>服务时间</p>
          <div>
            <div>{{ chatInfo.startDay | week }} 至 {{ chatInfo.endDay | week }}</div>
            <div>{{ chatInfo.time }}</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!--        <el-button @click="dialogVisible = false">取 消</el-button>-->
        <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <Cooperation ref="cooperation"></Cooperation>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from '@/store'
import { customerInfo, statisticsInfo } from '@/api/common'
import { userInfo } from '@/store/getters'
import { userLogin } from '@/api/login'
import Cooperation from '@/components/Cooperation/Cooperation.vue'

export default {
  components: { Cooperation },
  computed: {
    ...mapGetters(['currentHomeModal', 'userType', 'name', 'avatar', 'navClassify', 'userId', 'userInfo']),
  },
  data() {
    return {
      dialogVisible: false,
      chatInfo: {},
      keyword: '', //搜索值
      modeNum: 1, //1,2,3   首页  平台动态   详细信息
      // bottomList: ['服务商入驻', '服务提供商', '关于我们', '帮助中心', '法律声明', '客服服务'],
      bottomList: [
        { name: '服务商入驻', path: '/more/serviceProviderCertification' },
        { name: '服务提供商', path: '/more/serviceProvider' },
        { name: '关于我们', path: '/more/aboutUs' },
        { name: '平台规则', path: '/more/platformRules' },
        { name: '法律声明', path: '/more/legalNotices' },
        { name: '直播赋能系统', path: '/more/liveBroadcast' },
        { name: '矩阵视频号', path: '/more/videoId' },
        { name: '大数据', path: 'http://dp.jinniugongfu.com/bigData' },
      ],
      mainTopNav: [],
      mainBottomNav: [],
      // userInfo: {},
      statistics: {},
      WxUrl: 'https://35dinghuo.oss-cn-hangzhou.aliyuncs.com//2kjcy/openAccountkjcy/2023/05/22kjcy/167uPze.jpg',
    }
  },

  async mounted() {
    await this.getChatInfo()
    // this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    await this.queryPrimaryClassify()
    await this.getStatisticsInfo()
    this.mainTopNav = this.navClassify.slice(0, 7).map((v, i) => {
      return {
        isActiveCl: false,
        categoryName: v.categoryName,
        imgurl: {
          backgroundImage: `url(${require(`@/assets/images/home_nav${i + 1}.png`)} )`,
        },
        path: v.path,
        id: v.id,
        version: v.version,
      }
    })
    this.mainBottomNav = this.navClassify.slice(7, 14).map((v, i) => {
      return {
        isActiveCl: false,
        categoryName: v.categoryName,
        imgurl: {
          backgroundImage: `url(${require(`@/assets/images/home_nav${i + 8}.png`)} )`,
        },
        path: v.path,
        id: v.id,
        version: v.version,
      }
    })
    let tabNav = JSON.parse(localStorage.getItem('tabNav'))
    // console.log(tabNav)
    if (JSON.stringify(tabNav) != '{}') {
      // console.log(this.mainTopNav.findIndex(v=>v.id==tabNav.id),this.mainBottomNav.findIndex(v=>v.id==tabNav.id))
      if (this.mainTopNav.findIndex((v) => v.id == tabNav.id)) {
        let index = this.mainTopNav.findIndex((v) => v.id == tabNav.id)
        // console.log(index,'11')
        if (index >= 0) {
          this.mainTopNav[index].isActiveCl = true
        }
      } else if (this.mainBottomNav.findIndex((v) => v.id == tabNav.id) > -1) {
        let index = this.mainBottomNav.findIndex((v) => v.id == tabNav.id)
        if (index >= 0) {
          this.mainBottomNav[index].isActiveCl = true
        }
        // console.log(index,'22')
      } else {
        this.mainTopNav[0].isActiveCl = true
      }
    }
  },

  methods: {
    initiateCooperation(item) {
      // this.$emit('initiateCooperation',item)
      // console.log(item)
      if (this.userType === 'user') {
        this.$refs.cooperation.show({
          ...item,
          price: item.echoMap?.specs[0].specsBalance,
        })
      } else {
        this.$router.push({
          path: `/login?redirect=${this.$route.fullPath}`,
          replace: true,
        })
      }
    },
    //获取客服信息
    async getChatInfo() {
      let result = await customerInfo({})
      this.chatInfo = {
        qrcode: result.data[0].value,
        phone: result.data[1].value,
        startDay: result.data[2].value.split('_')[0],
        endDay: result.data[2].value.split('_')[1],
        time:
          this.$moment(result.data[3].value.split(',')[0]).format('HH:mm') +
          '-' +
          this.$moment(result.data[3].value.split(',')[2]).format('HH:mm'),
      }
    },
    // userInfo,

    ...mapActions(['queryPrimaryClassify', 'getUserInfo']),
    toProduct() {
      this.$router.push({
        path: '/more/product',
        query: {
          keyword: this.keyword,
        },
      })
    },
    //获取统计信息
    async getStatisticsInfo() {
      let result = await statisticsInfo()
      this.statistics = result.data
    },
    toGo(item) {
      if (item.name === '服务商入驻') {
        if (JSON.stringify(this.userInfo) == '{}') {
          this.$router.push({
            path: `/login?redirect=${this.$route.fullPath}`,
            replace: true,
          })
        } else {
          this.$router.push({ path: item.path })
        }
      } else if (item.name === '大数据') {
        window.open(item.path)
      } else {
        this.$router.push({ path: item.path })
      }
    },
    //发起咨询
    toWechat() {
      if (this.userType === 'user') {
        this.$router.push({
          path: '/more/chat',
          query: { serviceId: '0', providerId: '1561555533553467392' },
        })
      } else {
        this.$router.push({
          path: `/login?redirect=${this.$route.fullPath}`,
          replace: true,
        })
      }
    },
    cliTopNav(index, arr, obj) {
      this.$bus.$emit('tabChange')
      this.navClassify.forEach((item) => {
        if (item.id == obj.id) {
          this.WxUrl = item.wxCodeUrl
        }
      })
      this.mainTopNav.forEach((item) => {
        item.isActiveCl = false
      })
      this.mainBottomNav.forEach((item) => {
        item.isActiveCl = false
      })
      arr[index].isActiveCl = true
      this.$router.push(arr[index].path)
      localStorage.setItem('tabNav', JSON.stringify(obj))
    },
    // forClick(){
    //   let sumArr=[...this.mainTopNav,...this.mainBottomNav]
    //   this.$bus.$emit('tabChange')
    //   this.tabIndex=0
    //   sumArr[this.tabIndex].isActiveCl=true
    //   let newArr=[]
    //   setInterval(()=>{
    //     this.tabIndex++
    //     sumArr[this.tabIndex].isActiveCl=true
    //     let obj=
    //     localStorage.setItem('newArr', JSON.stringify(obj))
    //
    //
    //   },30000)
    // },
    toLogin() {
      this.$router.push('/login')
    },
    toPersonal(obj) {
      this.$router.push({
        path: '/more/personalCenter',
        query: obj,
      })
    },
    //登出
    signOut() {
      store.dispatch('LogOut').then(() => {
        this.$router.push({ path: '/home' })
      })
      // location.reload();
    },
  },
  watch: {
    $route: {
      handler(to) {
        if (to.path == '/index') {
          this.$nextTick(async (_) => {
            await this.queryPrimaryClassify()
            this.mainTopNav = this.navClassify.slice(0, 7).map((v, i) => {
              return {
                isActiveCl: false,
                categoryName: v.categoryName,
                imgurl: {
                  backgroundImage: `url(${require(`@/assets/images/home_nav${i + 1}.png`)} )`,
                },
                path: v.path,
                id: v.id,
                version: v.version,
              }
            })
            this.mainTopNav.forEach((item) => {
              item.isActiveCl = false
            })
            this.mainTopNav[0].isActiveCl = true
            localStorage.setItem('tabNav', JSON.stringify(this.mainTopNav[0]))
          })
        }
        // if(to.query.code){
        //   console.log(to.query.code)
        //   console.log(this.$store)
        //   this.$store
        //       .dispatch('signIn', {wechatCode:to.query.code,grantType:'wechat'})
        //       .then(() => {
        //         // console.log(11)
        //         // this.$store.dispatch('getUserInfo').then(() => {
        //         //   console.log(1)
        //         //   this.$router.push({
        //         //     path: to.$route.query.redirect || '/home',
        //         //     query:Object.defineProperty(to.$route.query, 'redirect', {value: undefined, writable: true})
        //         //   })
        //         // })
        //       })
        //
        // }
      },
      immediate: true,
    },
    // $route: (to) => {
    //   if (to.path == '/index') {
    //     this.$nextTick(async (_) => {
    //       await this.queryPrimaryClassify()
    //       this.mainTopNav = this.navClassify.slice(0, 7).map((v, i) => {
    //         return {
    //           isActiveCl: false,
    //           categoryName: v.categoryName,
    //           imgurl: {
    //             backgroundImage: `url(${require(`@/assets/images/home_nav${i + 1}.png`)} )`,
    //           },
    //           path: v.path,
    //           id: v.id,
    //           version: v.version,
    //         }
    //       })
    //       this.mainTopNav.forEach((item) => {
    //         item.isActiveCl = false
    //       })
    //       this.mainTopNav[0].isActiveCl = true
    //       localStorage.setItem('tabNav', JSON.stringify(this.mainTopNav[0]))
    //     })
    //   }
    //   if (to.query.code) {
    //     console.log(to.query.code)
    //     // userLogin({wechatCode:to.query.code,grantType:'wechat'}).then(res=>{
    //     //   console.log(res)
    //     // })
    //     this.$store
    //         .dispatch('signIn', {wechatCode: to.query.code, grantType: 'wechat'})
    //         .then(() => {
    //           this.$store.dispatch('getUserInfo').then(() => {
    //             console.log(1)
    //             this.$router.push({
    //               path: to.$route.query.redirect || '/home',
    //               query: Object.defineProperty(to.$route.query, 'redirect', {value: undefined, writable: true})
    //             })
    //             // this.$router.push('/')
    //           })
    //         })
    //         .catch(() => {
    //           // this.loading = false
    //         })
    //   }
    //
    // }
  },
}
</script>

<style lang="less" scoped>
.main_content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .mdoe1 {
    width: 100%;
    height: 100%;
  }

  .con_top {
    width: 100%;
    height: 100px;
    background: @bg-color;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 555px;
      height: 77px;
      background: url('@/assets/images/login_log.png') no-repeat;
      margin-left: 50px;
      cursor: pointer;
    }

    .right {
      display: flex;
      align-items: center;
      margin-right: 60px;

      .search {
        position: relative;
        width: 340px;
      }

      .ser_btn {
        border: 1px solid rgba(255, 255, 255, 0.8);
        width: 100px;
        height: 40px;
        background: rgba(255, 255, 255, 0);
        border-radius: 0 20px 20px 0px;
        color: rgba(255, 255, 255, 0.8);
        position: absolute;
        right: 1px;

        span {
          background: url('@/assets/images/home_search.png') no-repeat;
          display: inline-block;
          padding-left: 20px;
        }
      }

      .searchIpt {
        ::v-deep .el-input__inner {
          border: 1px solid rgba(255, 255, 255, 0.8);
          width: 240px;
          height: 40px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 20px 0 0 20px;
        }
      }

      .login,
      .consult {
        margin-left: 10px;
        border: 1px solid rgba(255, 255, 255, 0.8);
        width: 120px;
        height: 40px;
        background: rgba(255, 255, 255, 0);
        border-radius: 20px;

        color: rgba(255, 255, 255, 0.8);

        span {
          background: url('@/assets/images/home_login.png') no-repeat;
          display: inline-block;
          padding-left: 20px;
        }
      }

      .consult {
        span {
          background: url('@/assets/images/home_cons.png') no-repeat !important;
          padding-left: 25px;
        }
      }

      .person {
        color: #ffffff;
        font-size: 16px;
        line-height: 32px;

        .cover {
          width: 32px;
          height: 32px;
          border-radius: 100px;
          margin-right: 5px;
        }

        ::v-deep .el-icon--right {
          color: #ffffff;
        }

        ::v-deep .el-icon-chat-dot-round,
        ::v-deep .el-icon-document {
          margin-right: 5px;
        }

        .drop {
          padding: 0 15px;
          box-sizing: border-box;
          font-size: 16px;
          cursor: pointer;

          .name {
            color: #ffffff;
          }
        }
      }
    }
  }

  .isHome {
    background: url('@/assets/images/bg.png') no-repeat !important;
    background-size: 100%;
  }

  .home_main {
    height: calc(100% - 220px);

    background: linear-gradient(to bottom, #1e60e8, #04399c);
    position: relative;

    .top_nav {
      display: flex;
      position: absolute;
      z-index: 999;
      top: 0;
      width: 100%;

      .btn_nav {
        //width: 273px;
        width: 14.28%;
        height: 140px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 32, 93, 0.8);
        color: #fff;
        font-weight: 700;
        margin-right: 1px;
        font-family: 'MyFont', sans-serif;
        font-size: 20px;

        &:hover {
          background: linear-gradient(to bottom, #1e60e8, #04399c) !important;
        }

        cursor: pointer;

        span {
          display: inline-block;
          padding-left: 90px;
          height: 80px;
          background-repeat: no-repeat;
          line-height: 80px;
        }
      }

      .isActive {
        background: linear-gradient(to bottom, #1e60e8, #04399c) !important;
      }

      .boder_none {
        margin-right: 0 !important;
      }
    }

    .bottom_nav {
      top: calc(100% - 140px);
      z-index: 999;
    }
  }

  .con_bottom {
    width: 100%;
    height: 120px;
    background: @bg-color;
    box-sizing: border-box;
    padding: 5px 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .left {
      margin-left: 40px;
      color: #fff;
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;

      .ewm {
        width: 120px;
        height: 120px;
      }

      .text {
        width: 80px;
        height: 50px;
        font-size: 14px;
        margin-left: 15px;

        p {
          margin-bottom: 10px;
        }
      }
    }

    .bottom_center {
      width: 880px;
      height: 90px;
      //position: absolute;
      //left: 50%;
      //margin-left: -290px;
      //top: 5px;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }

    .bot_top {
      //display: flex;

      div {
        width: 105px;
        text-align: center;
        border-right: 1px solid #fff;
        padding-bottom: 2px;
        cursor: pointer;
      }

      .no_boder {
        border: none;
      }

      margin: 25px 0 10px 0;
    }

    .bot_bot {
      font-size: 12px;
      cursor: pointer;

      a {
        color: #ffffff;

        &:hover {
          color: #2d76f9;
        }
      }
    }
  }
}

::v-deep .el-input__inner {
  color: #ffffff !important;
}

.kfDialog {
  text-align: center;

  div {
    color: #202020;
  }

  p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.routerView {
  padding: 140px 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.wx {
  padding: 5px;
  background: #fff;
  border-radius: 10px;
}
</style>
