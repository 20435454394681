import { axios } from '@/utils/request'

/**
 * 企业、服务商认证
 */
export function authenticate(data) {
  return axios({
    url: `/api/kjcy/bUserCertification/create`,
    method: 'post',
    data
  })
}

/**
 * 企业、服务商信息查询
 */
export function informationQuery(data) {
  return axios({
    url: `/api/kjcy/bUserCertification/getInfo`,
    method: 'post',
    data
  })
}

/**
 * 我的订单
 */
export function myOrder(data) {
  return axios({
    url: `/api/kjcy/bOrder/mylist`,
    method: 'post',
    data
  })
}
