<template>
  <div id="app" ref="app">
    <router-view />
    <el-backtop target="#app" v-if="!show" ref="backtopRef">
      <div
        style="
           {
            height: 100%;
            width: 100%;
            background-color: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
            text-align: center;
            line-height: 40px;
            color: #2d76f9;
          }
        "
      >
        <img style="width: 20px; height: 20px" src="@/assets/images/top.png" />
      </div>
    </el-backtop>
  </div>
</template>
<script>
import { getUserTokenKey } from '@/utils/auth'
import { isMobile } from '@/utils/isMobile'
import DevicePixelRatio from "@/utils/devicePixelRatio";

export default {
  name: 'App',
  data() {
    return {
      show: true,
      backtopInstance:null
    }
  },
  mounted() {
    // 在组件渲染完成后，获取 el-backtop 组件的引用
    this.backtopInstance = this.$refs.backtopRef;
    this.show = this.isMobile()

    // console.log(getUserTokenKey())
    if (!getUserTokenKey() || getUserTokenKey() == undefined) {
      localStorage.removeItem('userInfo')
    }
    // this.keepRatio()
    // window.addEventListener('resize', () => {
    //   this.keepRatio()
    // });
      new DevicePixelRatio().init()

  },
  methods: {
    isMobile,
    keepRatio() {
      var ratio = 0 // 定义一个缩放比例
      var screen = window.screen // 获取窗口对象
      var ua = navigator.userAgent.toLowerCase()
      if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio // 像素大小的比例
      } else if (~ua.indexOf('msie')) {
        if (screen.deviceXDPI && screen.logicalXDPI) {
          ratio = screen.deviceXDPI / screen.logicalXDPI
        }
      } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
        ratio = window.outerWidth / window.innerWidth // 外部比例/内部比例：缩放比例
      }
      if (ratio) {
        ratio = Math.round(ratio * 100)
      }
      this.ratio = (ratio / 100).toFixed(2)
      document.body.style.zoom = 1 / this.ratio // 窗口视图除以缩放比例：即缩放还原
    },

  },
  watch:{
    $route:{
      handler(value){
       this.$nextTick(_=>{
         this.$refs.app.scrollTo({
           top: 0,
           behavior: 'smooth',
         });
       })
      },
      immediate:true
    }
  }

}
</script>
<style lang="less"></style>
