import router from './router'
import store from './store'
import { getUserTokenKey, getVisitorTokenKey } from '@/utils/auth'
import { Message } from 'element-ui'
import Vue from "vue";
const whiteList = ['/auth/signIn', '/auth/register', '/auth/resetPassword']
router.beforeEach(async (to, from, next) => {
  // window.scrollTo(0, 0); // 滚动到页面顶部
  // console.log(window)
  if (
    !store.getters.navClassify.length &&
    (getUserTokenKey() || getVisitorTokenKey())
  ) {
    await store.dispatch('queryPrimaryClassify').catch(err => {
      console.log(err)
    })
  }
  //是否有登录token
  if (getUserTokenKey()) {
    if (whiteList.includes(to.path)) {
      next({ path: '/' })
    } else {
      if (store.getters.userId) {
        next()
      } else {
        store
          .dispatch('getUserInfo')
          .then(() => {
            next()
          })
          .catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/' })
            })
          })
      }
    }
  } else {
    // localStorage.removeItem('userInfo')
    // console.log(222)
    //是否有游客token
    if (getVisitorTokenKey()) {
      if (to.meta.isSignIn) {
        // console.log(to.fullPath)
        // 需要登录，重定向到登录页
        next({
          path: `/login`,
          query:{
            redirect:to.path,
            ...to.query
          },
          replace: true
        })
      } else {
        next()
      }
      if (store.getters.userId) {
        next()
      } else {
        store
          .dispatch('getVisitorInfo')
          .then(() => {
            next()
          })
          .catch(err => {
            console.log(err)
          })
      }
    } else {
      // console.log(1)
      store.dispatch('getVisitorToken').then(() => {
        if (!store.getters.navClassify.length) {
          store
            .dispatch('queryPrimaryClassify')
            .then(() => {
              next()
              store.dispatch('getVisitorInfo')
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    }
  }
})
